@import "./colors";
@import "./fonts";

.batch-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .coffeebatches {
    th {
      padding: 0rem 0.5rem;
    }
    .th {
      &-1 {
        width: 20%;
      }
    }
    tr.hide {
      display: none;
    }
    td span {
        padding:0;
    }

  }
}
