@import "./colors";
@import "./fonts";

.farmers-list {
  margin-bottom: 0rem;

  thead {
    border-top-color: $light;
    border-top-width: 2px;
  }

  th {
    padding: 0rem 0.5rem;
  }

  .th {
    &-bio {
      width: 20%;
    }
  }

  tr.hide {
    display: none;
  }

  td span {
    padding: 0;
  }

}
