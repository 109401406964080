@import "./colors";
@import "./fonts";

.company {
  display: flex;
  padding: 3rem 2rem;
  overflow-y: scroll;
  @media (max-width: 600px) {
    flex-direction: column;
    padding: 3rem 0.5rem;
  }
  .logo {
    height: 2rem;
  }
  .pic {
    position: absolute;
    margin-top: 1rem;
    height: 9rem;
    width: 9rem;
    border-radius: 50%;
  }
  .card {
    width: 25rem;
    border-radius: 10px;
    @media (max-width: 600px) {
      width: auto;
    }
    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 1rem;
      height: 5rem;
      margin-bottom: 1rem;
      background-image: url("../assets/coffee.jpg");
    }
    &-footer {
      display: flex;
      justify-content: center;
      padding: 0.8rem;
    }
  }
  .info-row {
    display: flex;
    justify-content: space-between;
  }
  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.5rem 0rem 1rem 0rem;
  }
  .location {
    margin: 0.5rem 0rem 1.2rem 0rem;
  }s
  .title {
    font-size: 0.9rem;
  }
  .bio {
    font-size: 1.2rem;
  }
  .text-light {
    padding: 0rem;
    font-size: 1rem;
  }
  .scitem {
    display: flex;
    align-items: center;
  }
  .boxes {
    margin-left: 1rem;
    width: 80%;
    @media (max-width: 600px) {
      width: 100%;
      margin-top: 2rem;
      margin-left: 0rem;
    }
  }
  .box-container {
    display: flex;
    flex-direction: column;
    height: 100%
  }
  .map-container {
    margin-left: 2rem; 
    @media (max-width: 600px) {
      margin-left: 0rem;
    }  
  }
  .box {
    padding-left: 1.5rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    color: #9b9b9d;
    background: repeating-linear-gradient(45deg,#fff,#fff 20px,#fcfcfc 20px,#fcfcfc 40px);
    border-radius: 20px/30px;
    box-shadow: 0 0 15px -2px rgb(0 0 0 / 20%);
    outline: 3px solid $light;
    outline-offset: -12px;
    position: relative;
    width: 100%;
    height: 25%;
    @media (max-width: 600px) {
      margin-left: 0rem;
      min-height: 10rem;
      min-height: 17rem;
    }
  }
  .box:before {
    content: "Información de Contacto";
    color: $light;
    background: $white;
    position: absolute;
    font-size: 18px;
    margin-top: 10px;
    padding: 0px 15px;
    top: -10px;
    left: 10%;
  }
  .items {
    display: grid;
    gap: 0.7rem;
    grid-template-columns: auto auto auto;
    padding-top: 3rem;
    padding-left: 1rem;
    @media (max-width: 600px) {
      grid-template-columns: auto auto;
      padding-left: 0rem;
    }
  }
  .item {
    display: flex;
    flex-direction: column;
  }
}
