@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-brand: #e34235;
}

html,
body,
#root {
  height: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  max-width: 100%;
}

html,
body {
  @apply antialiased bg-body font-body dark:bg-dark dark:text-white;
}

.dynamic-html {
  h6 {
    @apply font-medium uppercase;
  }
  p {
    @apply mb-3;
  }
}

.text-case-inherit {
  text-transform: inherit;
}
.letter-space-inherit {
  letter-spacing: inherit;
}
.word-break-all {
  word-break: break-all;
}
.animation-delay-200 {
  animation-delay: 200ms;
}
.animation-delay-500 {
  animation-delay: 500ms;
}
.animation-delay-700 {
  animation-delay: 700ms;
}
.text-vertical {
  writing-mode: tb;
  text-orientation: upright;
}

/* Hide spin button for input type number */
input.spin-button-hidden {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Styles for Swiper slider */
.swiper {
  @apply flex flex-col;
}

.swiper-wrapper {
  @apply order-first;
}

.swiper-scrollbar {
  @apply relative bg-gray-200 rounded dark:bg-gray-600;

  &.swiper-horizontal {
    @apply z-10 mt-4 mb-0 h-0.5 w-full sm:mt-6;
  }

  &.swiper-vertical {
    @apply absolute top-0 right-1 z-10 h-full w-0.5;
  }

  &-drag {
    @apply relative bg-gray-900 rounded dark:bg-light-dark;

    &.swiper-horizontal {
      @apply top-[-1px] left-0 h-[3px] w-full shadow-sm;
    }

    &.swiper-vertical {
      @apply left-[-1px] top-0 h-full w-[3px] shadow-sm;
    }
  }

  &-cursor-drag {
    @apply cursor-move;
  }

  &-lock {
    @apply hidden;
  }
}

.transaction-table {
  border-spacing: 0 12px;
  margin-top: -12px;

  > thead {
    tr {
      > th {
        text-align: left;
      }
    }
  }
}

.text-heading-style {
  @apply text-sm font-medium tracking-wider;
}

/* React Share button custom styles */
.product-share {
  .react-share__Sharebutton {
    @apply flex flex-col items-center justify-center;
  }
}
